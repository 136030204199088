import React, { useMemo } from 'react';
import { selectLmsLists } from '../../../../store/features/lmsSlice';
import { useAppSelector } from '../../../../store/hook';
import { useParams } from 'react-router-dom';
import ReferenceManual from './ReferenceManual';
import ReferenceCafe from './ReferenceCafe';

const ReferenceRoom = () => {
  const { classId } = useParams();
  const lmsLists = useAppSelector(selectLmsLists);
  const courseId = useMemo(() => {
    if (lmsLists && classId) {
      return lmsLists.id === classId ? lmsLists.course?.id : '';
    } else {
      return '';
    }
  }, [lmsLists, classId]);

  return (
    <div className="flex flex-col gap-5 p-5">
      <Reference courseId={courseId} />
    </div>
  );
};

const HR = () => <hr className="border border-gray4" />;
const Reference = ({ courseId }: { courseId: string }) => {
  switch (courseId) {
    case 'Irmk2AAW2RWV-iHn': // 베이직
      return (
        <>
          <ReferenceManual
            title="베이직 클래스 교사용 매뉴얼"
            link="https://vo.la/yWWXU"
            data={[
              '[BASIC] 교재별 교안',
              '[BASIC] 교재별 음원',
              '[BASIC] 보조 자료',
            ]}
          />
          <HR />
          <ReferenceCafe type="home" />
        </>
      );
    case 'QJhUDePfAn__Y6na': // 키즈
      return (
        <>
          <ReferenceManual
            title="키즈 클래스 교사용 매뉴얼"
            link="https://vo.la/uOSkq"
            data={[
              '[KIDS] 교재별 교안',
              '[KIDS] 교재별 음원',
              '[KIDS] 보조 자료',
            ]}
          />
          <HR />
          <ReferenceCafe type="home" />
        </>
      );
    case 'XoaJAz_Vg6fJ1QxQ': // 어드벤스드
      return (
        <>
          <ReferenceManual
            title="어드밴스드 클래스 교사용 매뉴얼"
            link="https://vo.la/VGlmh"
            data={[
              '[ADVANCED] 교재별 교안',
              '[ADVANCED] 교재별 음원',
              '[ADVANCED] 워크 시트',
            ]}
          />
          <HR />
          <ReferenceCafe type="home" />
        </>
      );
    case 'VY6Yl4kxQsb3sYJZ': // WMW
      return (
        <>
          <ReferenceManual
            title="WMW 클래스 교사용 매뉴얼"
            link="https://vo.la/xiDEp"
            data={['[WMW] 교재별 교안', '[WMW] 교재별 음원&악보']}
          />
          <HR />
          <ReferenceCafe type="church" />
        </>
      );
    case 'GXHrE6RAfYafHfgs': // HFHJ
      return (
        <>
          <ReferenceManual
            title="HFHJ 클래스 교사용 매뉴얼"
            link="https://vo.la/tMVMn"
            data={[
              '[HFHJ] 교재별 교안',
              '[HFHJ] 교재별 음원',
              '[HFHJ] 보조 자료',
            ]}
          />
          <HR />
          <ReferenceCafe type="church" />
        </>
      );
    case 'jkfpzV9bk4sEmcEA': // 영복전
      return (
        <>
          <ReferenceManual
            title="영어로 복음 전하기 교사용 매뉴얼"
            link="https://vo.la/JQLHF"
            data={[
              '[영어로 복음 전하기] 교안',
              '[영어로 복음 전하기] 보조 자료',
              '[영어로 복음 전하기] 찬양 자료',
            ]}
          />
          <HR />
          <ReferenceCafe type="church" />
        </>
      );
    default: // 영성통
      return (
        <>
          <ReferenceManual
            title="영어성경 통째 암송 교사용 매뉴얼"
            link="https://vo.la/fyBLm"
            data={[
              '[영어성경 통째 암송] 교안',
              '[영어성경 통째 암송] 보조 자료',
            ]}
          />
          <HR />
          <ReferenceCafe type="church" />
        </>
      );
  }
};

export default ReferenceRoom;
