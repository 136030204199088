import {
  ApiResponse,
  Class,
  CourseLesson,
  PaginationResponse,
} from '../../../constants/types';
import { myLearningLessonInfoType } from '../../../constants/types/learning-type';
import instance from '../../axios/utils.ts/instance';
import { getErrorMessages } from '../util-func';

const classId = 'zBHuODhRge6wiqJ0';

/**
 * lms 수업하기 북/챕터 리스트 가져오기
 * @returns
 */
export const getBookChapterList_Light = async () => {
  const response = await instance.get<Class>(
    `/v1/class/chapter-list/${classId}`
  );
  return response.data;
};

/**
 * lms 수업하기 레슨 목록 가져오기
 * @param bookId 북/챕터 아이디. 필터링을 위한 값
 * @returns
 */
export const getLessonList_Light = async (bookId: string) => {
  const response = await instance.get<{ lessons: CourseLesson[] }>(
    `/v1/class/${classId}/chapter/${bookId}`
  );
  return response.data.lessons;
};

/**
 * lms 수업하기 콘텐츠 목록 가져오기
 * @param params 타입 지정 필요. 필터링을 위한 값
 * @returns
 */
export const getTakeClassList_Light = async (params: any) => {
  try {
    type ResponseType = ApiResponse<
      PaginationResponse<myLearningLessonInfoType[]>
    >;
    const response = await instance.get<ResponseType>(`/v1/teaching-only/wmw`, {
      params,
    });

    return response.data.data;
  } catch (e) {
    console.error(getErrorMessages(e));
  }
};

/**
 * lms 수업하기 콘텐츠 링크 가져오기
 * @param contentId
 * @returns
 */
export const getContentsDatas_light = async (contentId: string) => {
  try {
    const path = `/v1/teaching-only/contents/${contentId}`;
    const rtn = await instance.get(path);
    return rtn.data.data;
  } catch (e) {
    console.error(getErrorMessages(e));
  }
};
