import React from 'react';
import TextWinds from '../../../../components/common/TextWinds';
import ReferenceButton from './ReferenceButton';
import { openNewWindow } from '../../../../api/utils/util-func';

const ReferenceManual = ({
  title,
  link,
  data,
}: {
  title: string;
  link: string;
  data: string[];
}) => (
  <>
    <TextWinds type="title_h3">{title}</TextWinds>
    <ReferenceButton type="orange" child="매뉴얼 다운로드" link={link} />
    <TextWinds type="title_h4">1 ) 매뉴얼 이용 및 참고사항</TextWinds>
    <ol className="list-decimal pl-5 text-sm md:text-base">
      <li>효과적인 수업 진행을 위해 매뉴얼을 꼭 숙지해 주세요.</li>
      <li>교사용 매뉴얼은 수료생 전용 자료이며, 외부 유출이 금지됩니다.</li>
      <li>
        자료 용량이 크기 때문에 데이터 사용에 유의해 주세요. (PC 다운로드 권장)
      </li>
      <li>자료 다운로드 후 ZIP 파일의 압축을 풀어 사용해 주세요.</li>
      <li>홈스쿨 카페에 가입하시면 다양한 부가 자료를 이용하실 수 있습니다.</li>
    </ol>
    <TextWinds type="title_h4">2) 매뉴얼 자료 구성</TextWinds>
    <ul className="list-disc pl-5 text-sm md:text-base">
      {data.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  </>
);

export default ReferenceManual;
